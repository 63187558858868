body {
  margin: 0;
  display: grid;
  min-height: 100vh; 
  
  grid: auto;
  font-family: 'Lato', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; 
  /* width: 100%; 
  max-width: 100%;  */
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;


}

.container {
  margin-bottom: 0;

}


#root {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;

}

html {
  overflow-x: hidden; /* Hide horizontal scrollbar */
  width: 100%; /* Full-width */
  max-width: 100%; /* Maximum width */
  height: 100%;
  /* scroll-behavior: smooth; */
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;

}

  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  