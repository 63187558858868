.pricing-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.pricing-modal-content {
  background-color: #fff;
  padding: 50px;
  border-radius: 15px;
  width: 80%;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative; /* Add this to properly position the close button */
  overflow-y: auto;
}

.close-button {
  position: absolute;
  top: 50px;
  right: 50px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: black;
}

/* Media query for screens smaller than 1300px */
@media (max-width: 1200px) {
  .pricing-modal-content {
    width: 100%;
    height: 100%;
    max-width: none;
    border-radius: 0;
    padding: 20px; /* You can adjust this as needed */
  }

  .close-button {
    top: 20px; /* Closer to the edge */
    right: 20px; /* Closer to the edge */
  }
}
