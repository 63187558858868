.outside-faq-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.faq-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    background-color: #f9f9f9;
    width: 80%;
    margin-bottom: 50px;
    border-radius: 20px;
  }
  
  .faq-item {
    margin-bottom: 20px;
  }
  
  .faq-item:hover  {
    margin-bottom: 20px;
    background-color: #f0f0f0;
    border-radius: 20px;
  }

  .faq-question {
    cursor: pointer;
    padding: 10px;
    font-weight: bold;
    color: black;
    transition: color 0.3s ease;
  }
  
  .faq-question:hover {
    color: blue;
  }
  
  .arrow {
    margin-right: 10px;
  }
  
  .faq-answer {
    padding: 10px;
    padding-left: 33px;
    padding-bottom: 20px;
    margin-top: 5px;
  }
  