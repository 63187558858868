.language-container {
  position: relative;
  overflow: show;
  width: 100%;
  height: 500px;
  z-index: 1;
}

.language-text {
  position: absolute;
  font-size: 2em;
  opacity: 0.6;
  white-space: nowrap;
  animation: flow 20s linear infinite;
  z-index: 1;
  top: var(--top);
  left: var(--left);
  animation-delay: var(--delay);
}

.italic {
  font-style: italic;
}

@keyframes flow {
  to {
    left: 100%;
  }
}
