.content {
  margin-bottom: 20px;
  
}

.selectorContainer {
  display: flex;
  justify-content: space-between; /* modern UI often use space-between */
  gap: 20px;
  margin-bottom: 0;
  padding: 10px;
  border-radius: 8px; /* for smoother corners */
  
  /* z-index: 5; */
}

.selectorWidth,
.selectorWidthRight {
  width: 100%; /* Utilize the available space */
  border-radius: 4px; /* Rounded corners */
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2); /* subtle shadow for depth */
}

.selectorWithLabel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px;
}

.selectorWithLabel label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #333; /* dark enough for readability */
}


.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-left: 20px;
  /* margin-right: 20px; */
}

.titleContainer > h1 {
  margin: 0 auto;
}

.subTitleContainer {
  display: flex;
  align-items: center;
  gap: 40px;
}

.languageSelected {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.response-options {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 5px;
}

.response-option,
.response-option-selected {
  background-color: white;
  color: #333;
  border: 2px solid #00A86B;
  border-radius: 10px;  /* Reduced border-radius */
  padding: 6px 12px;  /* Reduced padding */
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  margin-left: 6px;  /* Reduced margin */
  margin-right: 6px;  /* Reduced margin */
  font-weight: 600;
  font-size: 14px;  /* Reduced font-size */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.response-option-selected {
  background-color: #00A86B;
  color: white;
  border-color: #008B5A;
}

.response-option:hover {
  background-color: #00A86B;
  color: white;
  border-color: #008B5A;
}

.response-option:active {
  background-color: #008B5A;
  color: #eee;
  border-color: #00724A;
}


.primary-language {
  display: flex;
  flex-wrap: wrap;  /* Add this line */

  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
}

.secondary-language {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
}

.third-language {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
}

.bottom-options {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.recording {
  background-color: rgb(249, 103, 103);
  color: white;
  border: 1px solid black;
  border-radius: 8px;
  padding: 4px 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-left: 4px;
  margin-right: 4px;
}

.not-recording {
  background-color: white;
  color: black;
  border: 1px solid black;
  border-radius: 8px;
  padding: 4px 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-left: 4px;
  margin-right: 4px;
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
  background-color: white;
  color: black;
  border: 1px solid black;
  border-radius: 8px;
  padding: 4px 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-left: 4px;
  margin-right: 4px;
}

.countdown {
  position: fixed; /* This makes the positioning relative to the viewport */
  top: 50%; /* Centers the element vertically */
  left: 50%; /* Centers the element horizontally */
  transform: translate(
    -50%,
    -50%
  ); /* Offsets the element by half of its own size to perfectly center it */
  z-index: 999; /* To render above all text */
  color: rgba(139, 0, 0, 0.6); /* Darkish red with 60% opacity */
  padding: 20px;
  border-radius: 10px;
  font-size: 6em; /* You can adjust this to suit your needs */
  text-align: center; /* To center the text within the countdown */
}

.app {
  flex: 1 0 auto;
}

.page-container {
  position: relative;
  min-height: 100vh;
  background-image: radial-gradient(circle at center, rgba(255, 255, 255, 1), rgba(173, 216, 230, 0.3), rgba(255, 255, 255, 0));

}

.footer {
  background: #333;
  color: white;
  padding: 3em;
  grid-column: 1 / -1;
  margin-top: auto;
}

@media screen and (max-width: 768px) {
  .titleContainer {
    /* flex-direction: column; */
    align-items: center;
    margin-left: 0px;

  }
  .titleContainer > h1 {
    margin-bottom: 20px;
  }
  .selectorContainer {
    gap: 20px; /* maximum gap size */
  }

  .selectorWidth {
    width: 70%;
  }
  .selectorWidthRight {
    width: 100%;
  }
  .secondary-language {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    text-align: center;
  }
}

.authButton {
  background: none;
  color: black;
  border: none;
  padding: 10px;
  text-decoration: underline;
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.practice-button {
  background-color: rgb(192, 255, 200);
  color: black;
  border: 1.3px solid black;
  border-radius: 45px;
  padding: 4px 6px;
  cursor: pointer;
  line-height: 1; /* Reset the line-height */
  vertical-align: middle; /* Adjust the vertical alignment */

  transition: background-color 0.3s ease, color 0.3s ease;
}

.languageSelected {
  justify-content: center;
  align-items: center;
  display: flex;
}

.login-message {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 0, 0, 0.6);
  color: white;
  padding: 20px;
  width: 360px;
  text-align: center;
  border-radius: 4px;
  border: 2px solid red;
  z-index: 1000;
}

.bookmark-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bookmark-container button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  outline: none;
  font-size: 2em; /* Adjust this value to make the icon bigger or smaller */
}

.button-saved {
  background: none;
  border: none;
  padding: 0;
  outline: none;
  color: black;
  cursor: pointer;
}

.button-not-saved {
  background: none;
  color: lightgray;
  border: 2px solid black;
  border-radius: 4px;
  cursor: pointer;
}


.tts-and-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.tts-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.play-icon {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 20px; /* Adjust this value to make the icon bigger or smaller */
  vertical-align: middle; /* Add this line */
}

.practice-icon {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 15px; /* Adjust this value to make the icon bigger or smaller */
}

.icon-button {
  background-color: white;
  color: black;
  border: 1px solid black;
  border-radius: 8px;
  padding: 7px 20px;
  cursor: pointer;
  line-height: 1; /* Reset the line-height */
  vertical-align: middle; /* Adjust the vertical alignment */

  transition: background-color 0.3s ease, color 0.3s ease;
}

.icon-button:hover {
  background-color: black;
  color: white;
}

.gender-selector {
  border: 1px solid black;
  border-radius: 8px;
  font-size: 1em;
  gap: 4px;
}

.inside-icon-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  font-size: 1em;
}

.speed-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

@keyframes fadeInOut {
  0% {
    opacity: 0.9;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.9;
  }
}

.chart-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeInOut 0.6s linear infinite;
}

.recording-and-graph {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.chart {
  display: flex;
  justify-content: center;
  align-items: center;
}

.recordings-list {
  margin-left: 40px;
}

.chart-dimensions {
  width: 800px;
  height: 400px;
}

@media (max-width: 850px) {
  .chart-dimensions {
    width: 400px;
    height: 200px;
  }
}
@media (max-width: 850px) {
  .chart-dimensions {
    width: 400px;
    height: 200px;
  }
}

@media (max-width: 1300px) {
  .recording-and-graph {
    flex-direction: column;
    align-items: center;
  }

  .recordings-list {
    margin-left: 0;
    margin-top: 20px; /* Add some space above the list when it's below the chart */
  }

  .tts-and-slider {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}

.outlined-container {
  border: 2px solid #ccc; /* This gives a thin outline */
  border-radius: 16px; /* This rounds the edges slightly */
  padding-bottom: 12px;
  margin: 10px auto; /* This adds some space above and below the container */
  width: 70%;
  background-color: white;
}

.outlined-subcontainer {
  border: 2px solid #ccc; /* This gives a thin outline */
  border-radius: 4px; /* This rounds the edges slightly */
  /* padding: 5px 0; This adds some space between the content and the border */
  margin: 10px 0; /* This adds some space above and below the container */
  width: 100%;
}

@media (max-width: 1400px) {
  .outlined-container {
    width: 90%;
    padding: 10px;
  }
  .outlined-subcontainer {
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .outlined-container {
    width: 100%;
    padding: 10px;
  }
  .outlined-subcontainer {
    width: 100%;
  }
}

.input-container {
  display: flex;
  align-items: center;
  border: 1px solid #6b6b6b;
  border-radius: 8px;
  padding: 4px 8px;
  margin-left: 24px;
  margin-right: 4px;
  font-size: 13.3px;
}

.input-label label {
  margin-right: 8px;
  font-weight: 600;
}

.number-container {
  direction: ltr;
}

.input-number {
  direction: rtl;
  width: 26px;
  border: none;
  outline: none;
}

.sentence-container {
  word-break: break-word;
}

.hoverable-word {
  white-space: normal; /* Allow the text to wrap */
  overflow-wrap: break-word; /* Break the word if necessary */
}
