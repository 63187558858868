.outside-slideshow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slideshow-container {
  position: relative;
  width: 500px;
  height: 200px;
  z-index: 2;  /* Higher z-index */
  margin: 150px 0;
}

@media screen and (max-width: 800px) {
  .slideshow-container {
    position: relative;
    width: 300px;
    height: 200px;
    z-index: 2;  /* Higher z-index */
  }
  .card-front img {
    height: 100% !important;  
    width: 310px !important;  
    border-radius: 8px;  
    opacity: 1;  
    border: 1px solid black; 
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.4);
  }

  .card-front {
    width: 310px !important;
  }
}

.slide {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.5s;
}

.slide:hover {
  cursor: pointer;
}

.slide.active {
  opacity: 1;
  /* background-color: white;  */
  background: none;

}

.card {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.5s;
  opacity: 1;  /* Not see-through */

}

.card.flipped {
  transform: rotateY(180deg);
  background-color: white;
  border-radius: 8px;  /* Add slight border-radius */

}

.card-front img {
  width: 100%;  /* Set the width to 100% of the parent container */
  height: auto;  /* Maintain aspect ratio */
  border-radius: 8px;  /* Add slight border-radius */
  opacity: 1;  /* Make it not see-through */
  border: 1px solid black;  /* Add a border */
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.4);
}

.card-back {
  border: 1px solid black;  /* Add a border */
  width: 95%;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;  
  background-color: white;
}

.card-front,
.card-back {
  backface-visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  opacity: 1;  /* Make it not see-through */
  /* background-color: white;   */
  background: none;
}

.card-back {
  transform: rotateY(180deg);
}

.card-back button {
  margin-top: .4em;
  margin-bottom: 1em;
  padding: 1em;
  background-color: #f2545b;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.prev, .next {
  position: absolute;
  top: 50%;
  font-size: auto;
  cursor: pointer;
  z-index: 1;
  width: 50px;  /* Set width */
  height: 50px;  /* Set height */
  line-height: 50px;  /* Center the text vertically */
  text-align: center;  /* Center the text horizontally */
  color: black;  /* Black text color */
  font-weight: bold;  /* Make bold */
  border-radius: 50%;  /* Make it a circle */
  background-color: white;  /* White background */
  border: 1px solid black;  /* Black border */
  opacity: 1;  /* Not see-through */
  transform: translateY(-50%);  /* Center vertically */
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);

}

.prev:hover, .next:hover {
  background-color: black;  /* Black background on hover */
  color: white;  /* White text on hover */
}


.prev {
  left: -70px;  /* Position to the left of the flag */
}

.next {
  right: -70px;  /* Position to the right of the flag */
}

