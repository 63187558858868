.pricing-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    height: 80vh;
    /* background-color: #f4f4f4; */
  }
  
  .pricing-title {
    margin-top: 50px;

    font-size: 36px;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
  }
  
  .pricing-plans {
    display: flex;
    gap: 20px;
  }
  
  .pricing-plan {
    flex: 1;
    padding: 20px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .pricing-plan h3 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .pricing-plan p {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .pricing-plan ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 20px;
  }
  
  .pricing-plan li {
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .pricing-button {
    padding: 15px 30px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 12px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: rgb(102, 194, 31);
    color: white;
    width: 100%;
  }
  
  .pricing-button:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    .pricing-plans {
      flex-direction: column;
      align-items: center;
    }
  
    .pricing-plan {
      width: 90%;  /* You can adjust this width */
      margin-bottom: 20px;
    }

    .pricing-page {
      height: 100%;
      margin-bottom: 100px;
    }
  }
  
  .checkmark {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    vertical-align: middle;
  }
  