.profile-container {
    position: relative;
    z-index: 4999;
  }
  
  .profile-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ffffff;
    border:#037971 2px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    top: 10px;
    right: 10px;
    z-index: 1000;
  }
  
  .popup-menu {
    position: absolute;
    top: 45px;
    right: 0;
    width: 200px;
    background-color: #037971;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 999;
  }
  
  .popup-menu button {
    width: 100%;
    padding: 10px;
    border: none;
    color: #eee;
    background: none;
    cursor: pointer;
    text-align: left;
    border-bottom: 1px solid #eee;
  }
  
  .popup-menu button:last-child {
    border-bottom: none;
  }
  
  .popup-menu button:hover {
    background-color: #03b5aa;
  }

  .popup-menu button:hover:first-child{
    border-radius: 5px 5px 0 0;
  }

  .popup-menu button:hover:last-child{
    border-radius: 0 0 5px 5px;
  }
  