.pricing-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.inside-pricing-container {
    display: flex;
}

h3 {
  display: flex;
  justify-content: left;
}

.free-box {
  flex: 1;
  border: 4px solid #a7a7a7; /* Thicker border */
  border-radius: 10px;
  padding: 30px; /* Increased padding to make it taller */
  margin: 10px 10px; /* 10px margin on all sides, making 20px between boxes */
  text-align: center;
  background-color: #ffffff;
  min-width: 220px; /* Increased minimum width */
  max-width: 340px; /* Increased maximum width */
  flex-basis: calc(33.333% - 20px); /* Default width, accounting for margin */
}

.pricing-box {
  flex: 1;
  border: 4px solid rgb(102, 194, 31); /* Thicker border */
  border-radius: 10px;
  padding: 30px; /* Increased padding to make it taller */
  margin: 10px 10px; /* 10px margin on all sides, making 20px between boxes */
  text-align: center;
  background-color: #ffffff;
  min-width: 220px; /* Increased minimum width */
  max-width: 340px; /* Increased maximum width */
  flex-basis: calc(33.333% - 20px); /* Default width, accounting for margin */
}

.price {
  display: flex;
  align-items: baseline;
}

.billing {
  font-size: 14px;
  margin-left: 5px;
}


.buy-button {
  background-color: rgb(102, 194, 31);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 20px 0;
  display: block;
  width: 100%; /* Full width */
}

.free-button {
  background-color: #afafaf;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 20px 0;
  display: block;
  width: 100%; /* Full width */
  cursor: default;
}

.free-button:hover {
  background-color: #afafaf;
}

ul {
    list-style-type: none; /* Remove default bullets */
    padding-left: 0; /* Remove padding */
    text-align: left; /* Left-align text */
  }

li {
    padding: 8px 0; /* Add some padding */
    font-size: 14px; /* Increase the font-size */
}
  
  /* Style for the checkmark image */
  img {
    height: 16px;
    width: 16px;
    margin-right: 8px; /* Add some space between the image and text */
  }


/* Media query for smaller screens */
@media (max-width: 768px) {
  .pricing-container {
    flex-direction: column;
    align-items: stretch;
    margin: 20px 20px;
  }

  .inside-pricing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 20px;
}

  .pricing-box {
    flex-basis: auto; /* Reset to auto */
    width: 100%; /* Full width */
    margin: 10px 0; /* Adjust margin */
  }

  .free-box {
    flex-basis: auto; /* Reset to auto */
    width: 100%; /* Full width */
    margin: 10px 0; /* Adjust margin */
  }
}

